<template>
    <teleport to="head">
        <link rel="canonical" :href="pageUrl" />
        <meta property="og:title" :content="`Latest / Back in stock products for ${gameName}`" />
        <meta property="og:description" :content="`See the latest / back in stock products for ${gameName}.`" />
        <meta property="og:image" :content="gameImagePath" />
    </teleport>
    <LoadingSpinner v-show="isLoading"></LoadingSpinner>
    <Header :breadcrumbs="breadcrumbs"/>

    <section class="flex-1 flex overflow-hidden dark:bg-gray-800">
        <!-- Primary column -->
        <section aria-labelledby="primary-heading" class="min-w-0 flex-1 h-full flex flex-col overflow-y-auto lg:order-last pl-0 sm:pl-2 p-4 pt-0 scrollbar-thin scrollbar-thumb-indigo-700 scrollbar-track-gray-300 overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full">

            <h2 class="font-medium text-xl text-gray-800 dark:text-gray-200 pb-2 flex flex-col">
                {{ displayTypePretty }}
                <small v-if="displayType === 'latest-products'" class="text-xs"><strong>Note: </strong>these are products new to TCGCompare (not necessarily products that have been released recently).</small>
            </h2>

            <div class="flex flex-col gap-y-2">
                <SubscriptionPanel
                    v-if="currentCountry && (displayType === 'latest-products' || displayType === 'latest-deals')"
                    :visible="true"
                    :country="currentCountry"
                    :show-email-needs-verifying-message="subscriptionNeedsVerifying"
                    :show-success-message="subscriptionSuccess"
                    :is-subscribing="isSubscribing"
                    subscription-type="deals"
                    @on-subscribe="userSubscribed"
                >
                    <template #title>
                        <p v-if="displayType === 'latest-deals'" class="inline">Subscribe to <CountryIcon class="-mt-1 mr-0" :code="currentCountry.code" :margin="false" /> {{ currentCountry.code }} Latest Deal Alerts</p>
                        <p v-if="displayType === 'latest-products'" class="inline">Subscribe to <CountryIcon class="-mt-1 mr-0" :code="currentCountry.code" :margin="false"/> {{ currentCountry.code }} New Product Alerts</p>
                    </template>
                    <template #description>
                        <p v-if="displayType === 'latest-deals'">Enter your email address to be notified of daily deals.</p>
                        <p v-if="displayType === 'latest-products'">Enter your email address to be notified when new products are discovered.</p>
                    </template>
                </SubscriptionPanel>
                <SortOptions
                    v-show="!isLoading"
                    :sort-options="sortOptions"
                    @on-sort-change="handleSortOptionsChange"
                    @on-filters-opened="handleFilterPanelOpened"
                />
            </div>

            <div class="mt-6 mb-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-y-10 gap-x-4">
                <AdWrapper ad-type="tcgplayer" class="group relative"/>
                <div v-if="products" v-for="(product, idx) in products" :key="idx" class="group relative">
                    <router-link :to="`${product.url}`" :title="product.name">
                        <span aria-hidden="true" class="absolute inset-0"/>
                        <div class="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                            <img
                                v-lazy="{src: product.image_path, loading: loadingGif}"
                                :alt="`Image for ${product.name}`"
                                class="w-full h-full object-center object-cover lg:w-full lg:h-full"
                            />
                        </div>

                        <span class="absolute top-1 right-0 text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1"
                          :class="{'text-white bg-indigo-700': product.in_stock, 'text-white bg-red-700': !product.in_stock}">
                          {{ product.in_stock ? "In Stock" : "Out of Stock" }}
                        </span>

                        <div class="mt-4 flex justify-between">
                            <div>
                                <h3 class="text-sm text-gray-700 dark:text-gray-400">
                                    {{ product.name }}
                                </h3>
                                <p v-if="product.price && product.average"
                                    class="text-lg font-medium text-gray-800 dark:text-gray-200">
                                    {{ product.price }}
                                    <small class="text-gray-600 dark:text-gray-300">(avg: {{ product.average }})</small>
                                </p>
                                <div>
                                    <p v-if="displayType === 'latest-products'" class="text-xs text-gray-600 dark:text-gray-500">First seen on TCGCompare </p>
                                    <p class="text-sm text-gray-500">{{ product.created_at.toRelative() }}</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <AdWrapper ad-type="google-product" class="group relative"/>
            </div>

            <Footer class=""/>
        </section>

        <!-- Secondary column (hidden on smaller screens) -->
        <aside class="hidden lg:block lg:flex-shrink-0 lg:order-first">
            <div class="h-full relative flex flex-col w-64 bg-white dark:bg-gray-800 overflow-y-auto scrollbar-thin scrollbar-thumb-indigo-700 scrollbar-track-gray-300 overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full" id="filters-panel">
                <Filters
                    v-show="!isLoading"
                    :filters="filters"
                    :sort-options="sortOptions"
                    :filter-panel-open="filterPanelOpen"
                    @on-options-change="handleFilterOptionsChange"
                    @on-filters-opened="handleFilterPanelClosed"
                />
            </div>
        </aside>
    </section>
</template>

<script lang="ts">
import useProductStore from "../../products/useProductStore";
import {useRoute, useRouter} from "vue-router";
import {defineComponent, computed, nextTick, onBeforeMount, ref, watch} from "vue";
import useCategoryStore from "../../categories/useCategoryStore";
import Header from "../../../components/Header.vue";
import useTitleManager from "../../../utils/useTitleManager";
import LoadingSpinner from "../../../components/LoadingSpinner.vue";
import Pagination from "../../../components/Pagination.vue";
import Filters from "../../categories/components/Filters.vue";
import loadingGif from "/loading.gif";
import Footer from "../../../layouts/components/Footer.vue";
import SortOptions from "../../categories/components/SortOptions.vue";
import CountryIcon from "../components/CountryIcon.vue";
import useLocalStorageStore from "../../../utils/useLocalStorageStore";
import {DateTime} from "luxon";
import useGameStore from "../../games/useGameStore";
import MoneyUtils from "../../../utils/MoneyUtils";
import AdWrapper from "../../../components/AdWrapper.vue";
import SubscriptionPanel from "../../../components/SubscriptionPanel.vue";
import emitter from "../../../utils/emitter";
import NotificationType from "../../../types/NotificationType";
import useSubscriptionStore from "../../subscriptions/useSubscriptionStore";
import useMiscInformationStore from "../../../utils/useMiscInformationStore";
import SubscriptionPanelSubmission from "../../../types/SubscriptionPanelSubmission";

export default defineComponent({
    name: "Latest",
    components: {
        SubscriptionPanel,
        AdWrapper,
        SortOptions,
        Filters,
        Footer,
        LoadingSpinner,
        Header,
        Pagination,
        CountryIcon
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const gameSlug = route.params.game;
        const gameName = ref();
        const gameImagePath = ref();
        const categorySlug = route.params.categorySlug;
        const productStore = useProductStore();
        const categoryStore = useCategoryStore();
        const gameStore = useGameStore();
        const localStorageStore = useLocalStorageStore();
        const miscInfoStore = useMiscInformationStore();
        const displayType = computed(() => route.name);
        const displayTypePretty = computed(() => displayType.value.split("-").join(" ").replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()}));
        const productList = ref([]);
        const products = computed(() => productList.value);
        const country = computed(() => localStorageStore.language);
        const currentCountry = computed(() => miscInfoStore.countries.find((x) => x.code === localStorageStore.language));
        const categoryName = ref(categoryStore.getCategoryNameBySlug(route.params.categorySlug));
        const { setTitlePrefix } = useTitleManager();
        const isLoading = ref(false);
        const active = ref(false);
        const filterPanelOpen = ref(false);

        const subscriptionStore = useSubscriptionStore();

        const showBackInStockSubscriptionPanel = ref(false);
        const showLowestPriceSubscriptionPanel = ref(false);
        const subscriptionNeedsVerifying = ref(false);
        const subscriptionSuccess = ref(false);
        const isSubscribing = ref(false);

        const selectedFilters = ref(route.query.filters ?? undefined);
        const selectedSort = ref(route.query.sort ?? undefined);
        const selectedPage = ref(route.query.page ?? undefined);

        const sortOptions = ref([
            {name: 'Newest First', value: "newest", selected: false},
            {name: 'Oldest First', value: "oldest", selected: false},
            {name: 'Alphabetically', value: "az", selected: false},
            {name: 'Avg Savings', value: "savings", selected: true},
        ]);
        const filters = ref([
            {
                id: "c",
                name: "Category",
                options: []
            },
            {
                id: 's',
                name: 'Availability',
                options: [
                    {value: "in_stock", label: 'In Stock', checked: true},
                    {value: "out_of_stock", label: 'Out of Stock', checked: false}
                ],
            },
            {
                id: 'sc',
                name: 'Set',
                options: []
            }
        ]);

        const breadcrumbs = [
            {
                label: displayTypePretty.value,
                path: displayType.value
            }
        ];

        const setup = async (checkParams = false) => {
            isLoading.value = true;

            const game = gameStore.getGameBySlug(gameSlug);
            gameName.value = game.name;
            gameImagePath.value = game.image_path;
            let options = {};

            if(selectedPage.value){
                options.page = selectedPage.value;
            }

            await Promise.all([
                await categoryStore.listSubCategories(gameSlug),
                await categoryStore.listCategories(gameSlug)
            ]);

            const idx = filters.value.findIndex(x => x.id === "sc");
            filters.value[idx].options = categoryStore.subCategories.reverse().map(x => {
                return {
                    value: x.slug,
                    label: x.name
                }
            });

            const catIdx = filters.value.findIndex(x => x.id === "c");
            filters.value[catIdx].options = categoryStore.categories.map(x => {
                return {
                    value: x.slug,
                    label: x.name
                }
            });
            if(displayType.value === "back-in-stock-products" || displayType.value === "latest-deals")
            {
                const stockIdx = filters.value.findIndex(x => x.id === "s");
                if(stockIdx > -1) {
                    filters.value.splice(stockIdx, 1);
                }

            }
            if(displayType.value === "back-in-stock-products" || displayType.value === "latest-products")
            {
                const stockIdx = sortOptions.value.findIndex(x => x.value === "savings");
                if(stockIdx > -1) {
                    sortOptions.value.splice(stockIdx, 1);
                }

            }

            if(checkParams){
                let existingFilters = [];
                if(route.query.c){
                    existingFilters.push({
                        id: "c",
                        values: route.query.c
                    })
                }

                existingFilters.push({
                    id: "country",
                    values: country
                });

                if(route.query.s){
                    existingFilters.push({
                        id: "s",
                        values: route.query.s
                    })
                }
                if(route.query.sc){
                    existingFilters.push({
                        id: "sc",
                        values: route.query.sc
                    })
                }
                selectedFilters.value = existingFilters;
            }

            if(selectedFilters.value){
                selectedFilters.value.forEach(x => {
                    if(x.values.length > 0){
                        options[x.id] = x.values
                    }
                });
                selectedFilters.value.forEach(function (e){
                    filters.value = filters.value.map((x) => {
                        if(x.id === e.id){
                            x.options.map((y) => {
                                e.values.split(',').map(v => {
                                    if (y.value === v) {
                                        y.checked = true;
                                    }
                                });
                            })
                        }
                        return x;
                    })
                });
            }
            if(selectedSort.value){
                options.sort = selectedSort.value;
                sortOptions.value = sortOptions.value.map((x) => {
                    x.selected = x.value === selectedSort.value;
                    return x;
                })
            }

            const params = new URLSearchParams(options);
            params.delete('country');
            let url = `/${gameSlug}/${displayType.value}`;
            if(params.toString().length>0){
                url = url+`?${params.toString()}`;
            }

            await Promise.all([
                await productStore.clearBackInStockProducts(),
                await productStore.clearLatestProducts(),
                await productStore.clearDeals()
            ]);
            await router.push(url);

            if(typeof options.limit === "undefined"){
                options.limit = 32;
            }

            switch (displayType.value){
                case "latest-products":
                    if(typeof options.country === "undefined"){
                        options.country = localStorageStore.language;
                    }
                    await productStore.getLatestProducts(options);
                    productList.value = productStore.latest?.map((x) => {
                        x.created_at = DateTime.fromISO(x.created_at)
                        x.url = (`/${gameSlug}/${x.category.slug}/${x.slug}`)+(x.country ? "/"+x.country.code : "");
                        return x;
                    });
                    break;

                case "back-in-stock-products":
                    if(typeof options.country === "undefined"){
                        options.country = localStorageStore.language;
                    }
                    await productStore.getBackInStockProducts(options);
                    productList.value = productStore.backInStock?.map((x) => {
                        x.created_at = DateTime.fromISO(x.created_at)
                        x.url = (`/${gameSlug}/${x.category.slug}/${x.slug}`)+(x.country ? "/"+x.country : "");
                        return x;
                    });
                    break;

                case "latest-deals":
                    options.limit = 50;
                    if(typeof options.country === "undefined"){
                        options.country = localStorageStore.language;
                    }
                    await productStore.getProductDeals(options);
                    productList.value = productStore.deals?.map((x) => {
                        x.price = MoneyUtils.getDisplayValue(x.country.code, MoneyUtils.convertToPounds(x.price));
                        x.average = MoneyUtils.getDisplayValue(x.country.code, MoneyUtils.convertToPounds(x.average));
                        x.created_at = DateTime.fromISO(x.created_at);
                        x.url = (`/${gameSlug}/${x.category.slug}/${x.slug}`)+(x.country ? "/"+x.country.code : "");
                        return x;
                    });
                    break;
            }

            isLoading.value = false;
            await nextTick(() => {
                const element = document.getElementById("main");
                element.scrollTop = 0;
            });
        }

        onBeforeMount(async () => {
            setTitlePrefix("Latest Products");
            await setup(true);
        })

        watch(() => localStorageStore.language, async () => {
            await router.replace({path: `/${gameSlug}/${displayType.value}`, query: route.query});
            await setup(true);
        });

        const handleFilterOptionsChange = async(values) => {
            selectedFilters.value = values.map((x) => {
                return {
                    'id': x.id,
                    'values': x.options.filter((y) => y.checked).map((z) => z.value).join(",")
                }
            });
            selectedPage.value = undefined;
            await setup();
        }

        const handleSortOptionsChange = async(value) => {
            selectedSort.value = value;
            selectedPage.value = undefined;
            await setup();
        }

        const handleFilterPanelOpened = () => {
            filterPanelOpen.value = true;
        }

        const handleFilterPanelClosed = () => {
            filterPanelOpen.value = false;
        }

        const userSubscribed = async (details: SubscriptionPanelSubmission) => {
            isSubscribing.value = true;
            try{
                const subscription = await subscriptionStore.subscribe({
                    type: details.subscriptionType,
                    name: details.name,
                    email: details.email,
                    country: details.country,
                    product: null
                });

                if(!subscription.user.email_verified){
                    subscriptionNeedsVerifying.value = true;
                }
                else{
                    subscriptionSuccess.value = true;
                }
            }
            catch (e){
                emitter.emit('show-notification', {
                    type: NotificationType.ERROR,
                    title: "Unable to unsubscribe",
                    body: e.errors.message ?? "There was an error completing your request, please try again later"
                });
            }
            isSubscribing.value = false;
        }

        return {
            isLoading,
            breadcrumbs,
            displayType,
            gameSlug,
            gameName,
            gameImagePath,
            categorySlug,
            products,
            categoryName,
            handleFilterOptionsChange,
            handleSortOptionsChange,
            selectedFilters,
            selectedSort,
            filters,
            sortOptions,
            loadingGif,
            active,
            handleFilterPanelOpened,
            handleFilterPanelClosed,
            filterPanelOpen,
            displayTypePretty,
            country,
            currentCountry,
            userSubscribed,
            showBackInStockSubscriptionPanel,
            showLowestPriceSubscriptionPanel,
            subscriptionNeedsVerifying,
            subscriptionSuccess,
            isSubscribing,
            pageUrl: window.location.href
        }
    }
});
</script>