<template>
    <div class="dark:bg-grey-700 rounded">
        <div class="mx-auto grid">
            <div class="px-6 pb-6 pt-6">
                <div class="mx-auto lg:mr-0">
                    <div class="flex flex-col justify-between sm:flex-row">
                        <h2 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-300 sm:text-4xl mx-auto sm:ml-0">{{ category.name }}</h2>
                        <img
                            v-lazy="{src: category.image_path, loading: loadingGif}"
                            :alt="`Image for ${category.name}`"
                            class="sm:rounded-lg sm:h-16 justify-self-end order-first sm:order-last"
                        />
                    </div>
                    <p class="mt-6 leading-8 text-gray-600 dark:text-gray-200 whitespace-pre-line">{{ category.description }}</p>
                    <dl class="mt-4 grid grid-cols-1 gap-8 sm:mt-4 sm:grid-cols-4 xl:mt-8 gap-y-3">
                        <div v-for="(value, key) in category.meta" :key="key" class="flex flex-col justify-between gap-y-3 border-l border-gray-900/10 dark:border-gray-200/10 pl-6">
                            <dd class="text-3xl font-semibold tracking-tight text-gray-900 dark:text-gray-300">{{ value }}</dd>
                            <dt class="text-sm leading-6 text-gray-600 dark:text-gray-400 justify-self-end">{{ MetaFormatter.prettyPrintKey(key) }}</dt>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import ProductSubCategory from "../../../types/ProductSubCategory";
import MetaFormatter from "../../../utils/MetaFormatter";
import loadingGif from "/loading.gif";
export default defineComponent({
    name: "SubCategoryDetails",
    computed: {
        MetaFormatter() {
            return MetaFormatter
        }
    },
    props: {
        category: {
            type: Object as PropType<ProductSubCategory>,
            required: true
        }
    },
    setup(){
        return {
            loadingGif
        }
    }
})
</script>