import {defineStore} from "pinia";
import { useStorage } from '@vueuse/core'

const useLocalStorageStore = defineStore({
    id: "localstorage",
    state: () => ({
        theme: "light" as string,
        subscriptionLayout: "grid" as string,
        language: useStorage('language', "GBR"),
        availableLanguages: [],
        hasDismissedBanner: false as boolean
    }),
    persist: true,
    actions: {
        setTheme(theme: string) {
            this.theme = theme;
        },
        setSubscriptionLayout(layout: string) {
            this.subscriptionLayout = layout;
        },
        setLanguage(language: string) {
            this.language = language;
        },
        setDismissedBanner() {
            this.hasDismissedBanner = true;
        }
    },
    getters: {
        getTheme(): string{
            return this.theme;
        },
        getSubscriptionLayout(): string{
            return this.subscriptionLayout;
        },
        getLanguage(): string{
            return this.language;
        },
        getAvailableLanguages(): string[]{
            return [
                "GBR",
                "USA",
                "CAN",
                "AUS",
                "FRA",
                "ESP",
                "NLD",
                "DEU",
                "BEL",
                "CHE",
            ];
        },
        getHasDismissedBanner(): boolean{
            return this.hasDismissedBanner;
        }
    }
});

export default useLocalStorageStore;