<template>
    <router-link :to="`/${category.game.slug}/${category.slug}`" :title="`See products from ${category.name}`">
        <div class="flex flex-col justify-between h-full">
<!--            <span aria-hidden="true" class="absolute inset-0"/>-->
            <div v-if="!smallImage"
                class="flex w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                <img
                    v-lazy="{ src: category.image_path, loading: loadingGif }"
                    class="w-full h-full object-center object-cover self-end"
                    :alt="`Image for ${category.name}`"
                />
            </div>
            <div v-else class="flex min-h-40 aspect-h-1 h-30 lg:aspect-none">
                <img
                    v-lazy="{ src: category.image_path, loading: loadingGif }"
                    class="w-full object-center self-center"
                    :alt="`Image for ${category.name}`"
                />
            </div>
            <div class="mt-4 flex justify-between">
                                <span class="absolute top-1 right-0 text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1"
                                      :class="{'text-white bg-indigo-700': category.product_count, 'text-white bg-red-700': !category.product_count}">
                                  {{ category.product_count }}
                                </span>
                <div class="ml-auto mr-auto">
                    <h3 class="text-base text-gray-700 dark:text-gray-200">
                        - {{ category.name }} -
                    </h3>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import loadingGif from "/loading.gif";

export default defineComponent({
    name: "CategoryItem",
    props:{
        category:{
            type: Object,
            required: true
        },
        smallImage:{
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(){

        return {
            loadingGif
        }
    }
})
</script>