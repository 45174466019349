<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <teleport to="head">
        <link rel="canonical" :href="pageUrl" />
        <meta property="og:title" :content="gameName" />
        <meta property="og:description" :content="`Compare pricing for products in the ${gameName} game.`" />
        <meta property="og:image" :content="gameImagePath" />
    </teleport>
    <LoadingSpinner v-show="isLoading"></LoadingSpinner>
    <Header />
    <div v-if="!isLoading" class="max-w-4xl flex flex-col mt-4 lg:max-w-full pr-4 pl-2">

        <div>
            <h2 class="text-gray-800 dark:text-gray-200 text-xs font-medium uppercase tracking-wide">Latest Updates</h2>
            <ul role="list" class="mt-3 grid grid-cols-1 gap-2 md:gap-6 sm:grid-cols-3">
                <li v-for="quickLink in quickLinks" :key="quickLink.name" class="col-span-1 flex shadow-sm rounded-md">
                    <a :href="quickLink.href" class="flex flex-1" :title="`View ${quickLink.name}`">
                    <div :class="[quickLink.bgColor, 'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md']">
                        <component :is="quickLink.icon" class="w-10"></component>
                    </div>
                    <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-600 rounded-r-md truncate">
                        <div class="flex-1 p-4 truncate text-sm md:text-sm">
                            <span class="text-gray-800 font-medium hover:text-gray-600 dark:text-gray-200 dark:hover:text-gray-100">
                                {{ quickLink.name }}
                            </span>
                        </div>
                        <div class="flex-shrink-0 pr-2">
                            <span class="w-8 h-8 inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <span class="sr-only">See More</span>
                                <ChevronRightIcon class="w-5 h-5" aria-hidden="true" />
                            </span>
                        </div>
                    </div>
                    </a>
                </li>
            </ul>
        </div>
        <div class="max-w-4xl p-4 lg:max-w-full">
            <div class="grid grid-cols-12 gap-x-4">

                <div class="pt-4 order-last sm:order-first col-span-12 sm:col-span-9">
                    <div class="flex justify-between">
                        <h2 class="text-gray-800 dark:text-gray-200 text-xl font-bold">Product Categories</h2>
                        <a
                            href="#product-sets"
                            title="Browse products by set"
                            type="button"
                            class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6z" />
                            </svg>
                            Browse By Set
                        </a>

                    </div>
                    <div class="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 gap-y-10 gap-x-6 xl:gap-x-8">
                        <CategoryItem
                            v-for="category in categories"
                            :key="category.id"
                            :category="category"
                            class="group relative hover:cursor-pointer"
                        />
                    </div>
                </div>
                <AdWrapper ad-type="tcgplayer" class="mt-4 order-first sm:order-last col-span-12 sm:col-span-3" />
            </div>
        </div>
        <div class="max-w-4xl p-4 lg:max-w-full">
            <div class="grid grid-cols-12 gap-x-4">

                <div class="pt-4 order-last sm:order-first col-span-12 sm:col-span-9">
                    <h2 class="text-gray-800 dark:text-gray-200 text-xl font-bold" id="product-sets">Product Sets</h2>
                    <div class="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-6 gap-y-10 gap-x-6 xl:gap-x-8">
                        <CategoryItem
                            v-for="category in subCategories"
                            :key="category.id"
                            :category="category"
                            :small-image="true"
                            class="group relative hover:cursor-pointer"
                        />
                    </div>
                </div>

                <AdWrapper ad-type="google" class="mt-4 order-first sm:order-last col-span-12 sm:col-span-3"/>
            </div>
        </div>

    </div>
</template>

<script>
import useCategoryStore from "../useCategoryStore";
import {computed, ref, onBeforeMount} from "vue";
import {useRoute} from "vue-router";
import Header from "../../../components/Header.vue";
import loadingGif from "/loading.gif";
import LatestProducts from "../../products/components/LatestProducts.vue";
import LatestDeals from "../../products/components/LatestDeals.vue";
import BackInStockProducts from "../../products/components/BackInStockProducts.vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/outline'
import {useMediaQuery} from "../../../utils/useMediaQuery";
import LoadingSpinner from "../../../components/LoadingSpinner.vue";
import useTitleManager from "../../../utils/useTitleManager";
import useGameStore from "../../games/useGameStore";
import { ChevronRightIcon, SparklesIcon, ShoppingCartIcon, TruckIcon } from '@heroicons/vue/solid'
import CategoryItem from "../components/CategoryItem.vue";
import AdWrapper from "../../../components/AdWrapper.vue";

export default {
    components: {
      AdWrapper,
        CategoryItem,
        LatestDeals,
        LatestProducts,
        BackInStockProducts,
        Header,
        ChevronDownIcon,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        LoadingSpinner,
        ChevronRightIcon,
        SparklesIcon,
        ShoppingCartIcon,
        TruckIcon
    },
    setup() {
        const route = useRoute();
        const { setTitlePrefix } = useTitleManager();
        const categoryStore = useCategoryStore();
        const gameStore = useGameStore();
        const game = ref();
        const gameSlug = route.params.game;
        const gameName = ref();
        const gameImagePath = ref();
        const categories = computed(() => categoryStore.categories);
        const subCategories = computed(() => categoryStore.subCategories.reverse());
        const { mediaFlags } = useMediaQuery("tailwind");
        const isLoading = ref(true);

        const setup = async () => {
            isLoading.value = true;

            await categoryStore.listCategories(gameSlug);
            await categoryStore.listSubCategories(gameSlug);
            game.value = gameStore.getGameBySlug(gameSlug);
            gameName.value = game.value?.name;
            gameImagePath.value = game.value?.image_path;

            isLoading.value = false;
        }

        onBeforeMount(async() => {
            await setup();
            setTitlePrefix(game.value.name);
        })

        const quickLinks = [
            { name: 'New Products', icon: 'SparklesIcon', href: `${gameSlug}/latest-products`, members: 16, bgColor: 'bg-green-800' },
            { name: 'Back In Stock', icon: 'TruckIcon', href: `${gameSlug}/back-in-stock-products`, members: 12, bgColor: 'bg-indigo-800' },
            { name: 'Deals', icon: 'ShoppingCartIcon', href: `${gameSlug}/latest-deals`, members: 16, bgColor: 'bg-red-800' },
        ]
        return {
            gameSlug,
            gameName,
            gameImagePath,
            categories,
            subCategories,
            loadingGif,
            mediaFlags,
            isLoading,
            quickLinks,
            pageUrl: window.location.href
        }
    },
}
</script>