<template>

    <ul class="flex text-gray-800 dark:text-gray-200 overflow-x-auto">
        <li
            v-for="country in countries"
            @click="currentTab(country)"
            class="bg-gray-100 dark:bg-gray-600 inline-block px-4 py-2 mr-1 rounded-t-lg cursor-pointer inline-flex align-middle border-b-0"
            :class="{'font-bold border dark:border dark:border-b-0 border-gray-400 dark:bg-gray-700': tab === country}">
            <CountryIcon class="self-center mb-1" :code="country"/>
            {{ country }}
        </li>
    </ul>
    <div
        class="md:h-[52vh] overflow-y-auto border border-gray-400 border-t-0 dark:bg-gray-700 product-price-list scrollbar-thin scrollbar-thumb-indigo-700">
        <div class="bg-gray-100 dark:bg-gray-700 shadow overflow-hidden rounded-tl-none">
            <div v-show="loading" class="flex items-center p-6">
                <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span class="ml-4 text-gray-800 dark:text-gray-200">
                    Searching for prices, please wait...
                </span>
            </div>
            <div v-show="noPrices" class="p-4 text-gray-800 dark:text-gray-200 font-weight-bold">
                No Prices found for the chosen country (
                <CountryIcon class="self-center mb-1" :code="tab"/>
                {{ tab }}), please try another or check back later.
            </div>
            <ul v-for="country in prices" role="list" class="divide-y divide-gray-200" v-show="tab === country.country">
                <li
                    v-show="showLowestPriceSubscriptionPanel"
                >
                    <SubscriptionPanel
                        :visible="true"
                        :country="currentCountry"
                        :show-email-needs-verifying-message="subscriptionNeedsVerifying"
                        :show-success-message="subscriptionSuccess"
                        :is-subscribing="isSubscribing"
                        subscription-type="lowest-price"
                        @on-subscribe="userSubscribed"
                    >
                        <template #title>
                            <p class="inline">Subscribe to
                                <CountryIcon class="-mt-1 mr-0" :code="currentCountry.code" :margin="false"/>
                                {{ currentCountry.code }} Price Alerts
                            </p>
                        </template>
                        <template #description>
                            <p>Enter your email address to be notified when a new lowest price is found for this
                                product.</p>
                        </template>
                    </SubscriptionPanel>
                </li>
                <li
                    v-show="showBackInStockSubscriptionPanel"
                    class="py-2"
                >
                    <SubscriptionPanel
                        :visible="true"
                        :country="currentCountry"
                        :show-email-needs-verifying-message="subscriptionNeedsVerifying"
                        :show-success-message="subscriptionSuccess"
                        :is-subscribing="isSubscribing"
                        subscription-type="back-in-stock"
                        @on-subscribe="userSubscribed"
                    >
                        <template #title>
                            <p class="inline">Subscribe to
                                <CountryIcon class="-mt-1 mr-0" :code="currentCountry.code" :margin="false"/>
                                {{ currentCountry.code }} Stock Alerts
                            </p>
                        </template>
                        <template #description>
                            <p>Enter your email address to be notified when this product is back in stock.</p>
                        </template>
                    </SubscriptionPanel>
                </li>
                <li class="py-2 px-4 flex justify-end">
                    <div class="flex items-center">
                        <span class="mr-3 text-xs">
                            <span class="text-xs text-gray-900 dark:text-gray-200">Hide eBay results?</span>
                          </span>
                        <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
                        <Switch v-model="hideEbay"
                                class="group relative inline-flex h-4 w-8 flex-shrink-0 cursor-pointer items-center justify-center rounded-full">
                            <span class="sr-only">Hide ebay results?</span>
                            <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-md"/>
                            <span aria-hidden="true"
                                  :class="[hideEbay ? 'bg-indigo-600' : 'bg-gray-200 dark:bg-gray-500', 'pointer-events-none absolute mx-auto h-4 w-8 rounded-full transition-colors duration-200 ease-in-out']"/>
                            <span aria-hidden="true"
                                  :class="[hideEbay ? 'translate-x-3' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-4 w-5 transform rounded-full border border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-400 shadow ring-0 transition-transform duration-200 ease-in-out']"/>
                        </Switch>
                    </div>
                </li>
                <li v-for="price in country.prices" :key="prices.id" :class="`s-${price.site.slug} relative`"
                    v-show="price.show">
                    <a
                        :href="price.url"
                        class="block hover:bg-gray-50 dark:hover:bg-indigo-800"
                        target="_blank"
                        @click="productLinkClicked(price.id, price.site.name)"
                        :title="`View '${ price.name }' on ${ price.site.name }`"
                    >
                        <div class="flex items-center px-4 py-4 sm:px-6">
                            <div class="min-w-0 flex-1 flex items-center">
                                <div class="min-w-0 flex-1 pr-4 md:grid md:gap-2">
                                    <div class="flex flex-col">
                                        <span class="text-sm font-medium text-indigo-600 dark:text-gray-200 truncate flex flex-row gap-x-1 items-center">
                                            <SiteIcon
                                                :name="price.site.name"
                                                :url="price.site.image_path"
                                                size="medium"
                                            />
                                            <span v-if="price.site.name !== 'eBay' && price.site.name !== 'Amazon'"
                                                  class="text-lg">
                                              {{ price.site.name }}
                                            </span>
                                            <span v-if="price.site.discount_code"
                                                  class="inline-flex items-center rounded-full bg-indigo-800 px-2.5 py-0.5 text-xs font-medium text-white">
                                                Discount Code
                                            </span>
                                        </span>
                                        <span class="text-xl font-bold text-gray-900 dark:text-gray-200 flex items-center gap-x-2"
                                           :class="{'text-green-500 dark:text-green-500': price.cheapest}">
                                            {{
                                                MoneyUtils.getDisplayValue(price.site.country, price.price)
                                            }}
                                            <span v-if="price.site.name === 'eBay' || price.site.name === 'Amazon'"
                                                  class="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                                                Not Verified
                                            </span>
                                            <span v-if="price.site.name === 'eBay' || price.site.name === 'Amazon'"
                                                  class="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                                                Affiliate*
                                            </span>
                                            <span v-if="price.cheapest"
                                                  class="inline-flex items-center rounded-full bg-indigo-800 px-2.5 py-0.5 text-xs font-medium text-white uppercase">
                                                Cheapest
                                            </span>
                                        </span>
                                        <span v-if="price.site.discount_code && price.site.discount_description"
                                            class="text-gray-200 dark:text-gray-200 text-bold bg-indigo-700 border-dashed border-white border rounded text-center p-2"
                                        >
                                                Use code <span class="text-green-500 font-bold">"{{price.site.discount_code}}"</span> for {{price.site.discount_description}}

                                        </span>
                                    </div>
                                    <div class="col-span-2 text-xs text-gray-900 dark:text-gray-200">
                                        {{ price.name }}
                                    </div>
                                    <div class="">
                                        <div class="font-bold"
                                             :class="[price.in_stock? 'text-green-500':'text-red-500']">
                                            {{ price.in_stock ? "In stock" : "Out of stock" }}
                                        </div>
                                        <div>
                                            <p class="text-xs text-gray-900 dark:text-gray-200">
                                                Last Updated:
                                                {{ ' ' }}
                                                <time :datetime="price.created_at">{{
                                                        FormattingUtils.formatDate(price.created_at, "yyyy-MM-dd HH:mm")
                                                    }}
                                                </time>
                                            </p>
                                            <div v-if="price.feedback" class="flex flex-row gap-x-2 mt-2">
                                            <span class="flex justify-between dark:text-gray-100 gap-x-1">
                                              <EyeIcon class="h-5 w-5 text-indigo-400"
                                                       :title="`${price.visits} viewed`"/>
                                              {{ price.visits }}
                                            </span>
                                                <span class="flex justify-between dark:text-gray-100 gap-x-1">
                                              <ThumbUpIcon class="h-5 w-5 text-green-400"
                                                           :title="`${price.feedback.up} found this useful`"/>
                                              {{ price.feedback.up }}
                                            </span>
                                                <span class="flex justify-between dark:text-gray-100 gap-x-1">
                                              <ThumbDownIcon class="h-5 w-5 text-red-400"
                                                             :title="`${price.feedback.up} didn't find this useful`"/>
                                              {{ price.feedback.down }}
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ChevronRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
                            </div>
                        </div>
                    </a>
                    <transition>
                        <PriceFeedbackForm
                            v-show="showFeedbackForm.includes(price.id)"
                            :product-price="price"
                            @feedback-given="feedbackClicked"
                        />
                    </transition>
                </li>
            </ul>
        </div>
    </div>
    <small class="text-gray-700 dark:text-gray-200">*Note: eBay/Amazon listings are not verified, and may not match the
        product/stock displayed.</small>
</template>
<script lang="ts">

import {computed, defineComponent, onBeforeMount, PropType, ref, watch} from "vue";
import {StarIcon} from '@heroicons/vue/solid';
import {
    HeartIcon,
    MinusSmIcon,
    PlusSmIcon,
    ChevronRightIcon,
    ThumbUpIcon,
    ThumbDownIcon,
    EyeIcon
} from '@heroicons/vue/outline';

import MoneyUtils from "../../../utils/MoneyUtils";
import FormattingUtils from "../../../utils/FormattingUtils";
import {sendFathomEvent} from "../../fathom/fathomUtils";
import CountryIcon from "./CountryIcon.vue";
import useProductStore from "../useProductStore";
import _ from "lodash";
import PriceFeedbackForm from "./PriceFeedbackForm.vue";
import SubscriptionPanel from "../../../components/SubscriptionPanel.vue";
import SubscriptionPanelSubmission from "../../../types/SubscriptionPanelSubmission";
import useMiscInformationStore from "../../../utils/useMiscInformationStore";
import useSubscriptionStore from "../../subscriptions/useSubscriptionStore";
import Product from "../../../types/Product";
import emitter from "../../../utils/emitter";
import NotificationType from "../../../types/NotificationType";
import SiteIcon from "./SiteIcon.vue";
import {Switch} from "@headlessui/vue";

export default defineComponent({
    name: "ProductPriceList",
    components: {
        Switch,
        SiteIcon,
        SubscriptionPanel,
        CountryIcon,
        ChevronRightIcon,
        HeartIcon,
        MinusSmIcon,
        PlusSmIcon,
        StarIcon,
        PriceFeedbackForm,
        ThumbUpIcon,
        ThumbDownIcon,
        EyeIcon
    },
    props: {
        product: {
            type: Object as PropType<Product>,
            required: true
        },
        countries: {
            type: Array,
            required: true
        },
        tab: {
            type: String,
            required: false,
            default: "GBR"
        },
    },
    emits: [
        'onCountryCodeChange',
        'productLinkClicked',
        'productFeedbackClicked'
    ],
    setup(props, context) {
        const prices = ref([]);
        const noPrices = ref(false);
        const productStore = useProductStore();
        const loading = ref(false);
        const showFeedbackForm = ref([]);
        const miscInfoStore = useMiscInformationStore();
        const subscriptionStore = useSubscriptionStore();

        const showBackInStockSubscriptionPanel = ref(false);
        const showLowestPriceSubscriptionPanel = ref(false);
        const subscriptionNeedsVerifying = ref(false);
        const subscriptionSuccess = ref(false);
        const isSubscribing = ref(false);
        const hideEbay = ref(false);

        onBeforeMount(async () => {
            await checkPricesForChosenCountry();
        })
        const tab = computed(() => props.tab);

        const currentCountry = computed(() => miscInfoStore.countries.find((x) => x.code == props.tab));

        const currentTab = (countryCode: string) => {
            if (tab.value !== countryCode) {
                context.emit('onCountryCodeChange', countryCode);
            }
        }

        const getPrices = async () => {
            await productStore.getProductPrices(
                productStore.activeProduct!.category.game!.slug,
                productStore.activeProduct!.category.slug,
                productStore.activeProduct!.slug,
                props.tab
            );

            prices.value.push({
                country: props.tab,
                prices: productStore.activeProductPrices
            });
        }

        const getEbayPrices = async () => {
            await productStore.getEbayPrices(props.tab);
            let idx = prices.value?.findIndex((x) => x.country == props.tab);
            if (idx < 0) {
                prices.value.push({
                    country: props.tab,
                    prices: productStore.ebayPrices
                })
            } else {
                prices.value[idx]?.prices.push(...productStore.ebayPrices);
                prices.value[idx].prices = _.orderBy(prices.value[idx].prices, ['in_stock', 'price'], ['desc', 'asc']);
            }
        }

        const productLinkClicked = (productPriceId: string | number, siteName: string) => {
            sendFathomEvent(`VISIT_SITE: ${siteName}`);
            if (!isNaN(productPriceId)) {
                context.emit('productLinkClicked', productPriceId);
                showFeedbackForm.value.push(productPriceId);
            }

            let idx = prices.value?.findIndex((x) => x.country == props.tab);
            if (idx > -1) {
                const priceIdx = prices.value[idx]['prices'].findIndex((x) => x.id == productPriceId);
                if (priceIdx > -1) {
                    prices.value[idx]['prices'][priceIdx].visits++;
                }
            }
        }

        const feedbackClicked = (details) => {
            if (!isNaN(details["product_price_id"])) {
                context.emit('productFeedbackClicked', details);
            }
            const index = showFeedbackForm.value.indexOf(details["product_price_id"]);
            if (index > -1) {
                setTimeout(() => {
                    showFeedbackForm.value.splice(index, 1);
                }, 5000);
            }

            let idx = prices.value?.findIndex((x) => x.country == props.tab);
            if (idx > -1) {
                const priceIdx = prices.value[idx]['prices'].findIndex((x) => x.id == details["product_price_id"]);
                if (priceIdx > -1) {
                    if (details['score'] > 0) {
                        prices.value[idx]['prices'][priceIdx].feedback.score = prices.value[idx]['prices'][priceIdx].feedback.score + 1;
                        prices.value[idx]['prices'][priceIdx].feedback.up++;
                    } else {
                        prices.value[idx]['prices'][priceIdx].feedback.score = prices.value[idx]['prices'][priceIdx].feedback.score - 1;
                        prices.value[idx]['prices'][priceIdx].feedback.down++;
                    }
                }
            }
        }

        const checkPricesForChosenCountry = async () => {
            loading.value = true;
            noPrices.value = false;
            prices.value = [];
            await Promise.all([
                await getPrices(),
                await getEbayPrices()
            ]);
            let idx = prices.value.findIndex((x) => x.country === props.tab);
            loading.value = false;
            if (prices.value[idx].prices.length < 1) {
                noPrices.value = true;
                showLowestPriceSubscriptionPanel.value = false;
                showBackInStockSubscriptionPanel.value = true;
            } else {
                noPrices.value = false;
                if (prices.value[idx].prices[0]) {
                    prices.value[idx].prices[0].cheapest = true;
                }
                let inStock = prices.value[idx].prices.find((x) => x.in_stock === 1);
                if (!inStock) {
                    showLowestPriceSubscriptionPanel.value = false;
                    showBackInStockSubscriptionPanel.value = true;
                } else {
                    showLowestPriceSubscriptionPanel.value = true;
                    showBackInStockSubscriptionPanel.value = false;
                }
                prices.value[idx].prices = prices.value[idx].prices.map((p) => {
                    return {...p, show: true}
                });
                hideEbayIfRequired();
            }
        }

        const sortByDiscountedFirst = (a, b) => {
            // Check stock status
            if (a.in_stock !== b.in_stock) {
                return a.in_stock ? -1 : 1;
            }

            // Check if prices have discount codes
            const aHasDiscount = a.site.discount_code ? 1 : 0;
            const bHasDiscount = b.site.discount_code ? 1 : 0;

            if (aHasDiscount !== bHasDiscount) {
                return bHasDiscount - aHasDiscount;
            }

            // Sort by price in ascending order if stock status and discount codes are the same
            return a.price - b.price;
        }

        const hideEbayIfRequired = () => {
            let idx = prices.value.findIndex((x) => x.country === props.tab);
            prices.value[idx].prices = prices.value[idx].prices.map(function (p) {
                if (p.site.name == "eBay") {
                    return {
                        ...p,
                        show: !hideEbay.value
                    }
                }
                return p;
            })
            prices.value[idx].prices = _.orderBy(prices.value[idx].prices, ['in_stock', 'site.discount_code', 'price'], ['desc', 'asc']);
        }

        const userSubscribed = async (details: SubscriptionPanelSubmission) => {
            isSubscribing.value = true;
            try {
                const subscription = await subscriptionStore.subscribe({
                    type: details.subscriptionType,
                    name: details.name,
                    email: details.email,
                    country: details.country,
                    product: props.product
                });

                if (!subscription.user.email_verified) {
                    subscriptionNeedsVerifying.value = true;
                    sendFathomEvent(`SUBSCRIBED_PRICE_EXISTING: ${details.country.code}`);
                } else {
                    subscriptionSuccess.value = true;
                    sendFathomEvent(`SUBSCRIBED_PRICE_NEW:  ${details.country.code}`);
                }
            } catch (e) {
                sendFathomEvent(`SUBSCRIBED_PRICE_ERROR:  ${details.country.code}`);
                emitter.emit('show-notification', {
                    type: NotificationType.ERROR,
                    title: "Unable to unsubscribe",
                    body: e.errors.message ?? "There was an error completing your request, please try again later"
                });
            }
            isSubscribing.value = false;
        }

        watch(() => props.tab, async () => {
            subscriptionNeedsVerifying.value = false;
            subscriptionSuccess.value = false;
            isSubscribing.value = false;
            showBackInStockSubscriptionPanel.value = false;
            showLowestPriceSubscriptionPanel.value = false;
            await checkPricesForChosenCountry();
        });

        watch(() => hideEbay, () => {
            hideEbayIfRequired();
        }, {deep: true});

        return {
            loading,
            showFeedbackForm,
            prices,
            currentCountry,
            MoneyUtils,
            FormattingUtils,
            productLinkClicked,
            feedbackClicked,
            currentTab,
            tab,
            noPrices,
            userSubscribed,
            showBackInStockSubscriptionPanel,
            showLowestPriceSubscriptionPanel,
            subscriptionNeedsVerifying,
            subscriptionSuccess,
            isSubscribing,
            hideEbay
        }
    }
});
</script>
<style>
.v-leave-from {
    opacity: 1
}

.v-leave-to {
    opacity: 0
}

.v-leave-active {
    transition: opacity 1s ease
}
</style>