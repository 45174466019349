<template>
    <div v-if="!isLoading">
        <div class="flex flex-grow justify-between sticky top-0">
            <strong class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200">
                Recently Added Products
            </strong>
            <router-link :to="`/${gameSlug}/latest-products`" class="px-2.5 py-0.5 mr-1 rounded-full text-xs font-medium bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200">
                Show More
            </router-link>
        </div>
        <ul role="list" class="divide-y divide-gray-200 pt-4">
            <li v-for="product in products" :key="product.id" class="py-4">
                <router-link :to="`/${gameSlug}/${product.category.slug}/${product.slug}`" :title="product.name">
                    <div class="flex space-x-3">
                        <img
                            class="h-12 w-12 object-center object-cover rounded-full"
                            v-lazy="{src: product.image_path, loading: loadingGif}"
                            :alt="`Picture of ${product.name}`"
                        />
                        <div class="flex-1 space-y-1">
                            <div class="flex items-center justify-between">
                                <h3 class="text-sm font-medium dark:text-gray-200">{{ product.name }}</h3>
                            </div>
                            <p class="text-sm text-gray-500">{{ product.category.name }}</p>
                            <p class="text-sm text-gray-500">{{ product.created_at.toRelative() }}</p>
                        </div>
                    </div>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import {computed, onBeforeMount, ref} from "vue";
import {useRoute} from "vue-router";
import useProductStore from "../useProductStore";
import loadingGif from "/loading.gif";
import { DateTime } from "luxon";

export default {
    setup() {
        const route = useRoute();
        const productStore = useProductStore();
        const gameSlug = route.params.game;
        const categorySlug = route.params.categorySlug;
        const products = computed(() => productStore.latest.map((x) => {
            x.created_at = DateTime.fromISO(x.created_at)
            return x;
        }));
        const isLoading = ref(false);

        onBeforeMount(async () => {
            isLoading.value = true;
            await productStore.getLatestProducts();
            isLoading.value = false;
        })
        return {
            gameSlug,
            categorySlug,
            products,
            loadingGif,
            isLoading,
        }
    },
}
</script>