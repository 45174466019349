<template>
  <router-link :to="`/${gameSlug}/${categorySlug}/${product.slug}`" :title="product.name">
    <span aria-hidden="true" class="absolute inset-0"/>
    <div class="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
      <img
          v-lazy="{src: product.image_path, loading: loadingGif}"
          :alt="`Image for ${product.name}`"
          class="w-full h-full object-center object-cover lg:w-full lg:h-full"
      />
    </div>
    <div class="mt-4 flex justify-between">
      <span class="absolute top-1 right-0 text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1"
            :class="{'text-white bg-indigo-700': product.in_stock, 'text-white bg-red-700': !product.in_stock}">
        {{ product.in_stock ? "In Stock" : "Out of Stock" }}
      </span>
      <div>
        <h3 class="text-lg text-gray-700 dark:text-gray-400">
          {{ product.name }}
        </h3>
      </div>
      <p class="text-sm font-medium text-gray-900">{{ product.price }}</p>
    </div>
  </router-link>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import Product from "../../../types/Product";
import loadingGif from "/loading.gif";

export default defineComponent({
  name: "CategoryProduct",
  props:{
    product:{
      type: Object as PropType<Product>,
      required: true
    },
    gameSlug:{
      type: String,
      required: true
    },
    categorySlug:{
      type: String,
      required: true
    }
  },
  setup(){
    return {
      loadingGif
    }
  }
})
</script>