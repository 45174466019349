<template>
    <div class="lg:hidden text-lg mb-4 text-gray-600 dark:text-gray-200">
        Set Information
    </div>
    <div class="flex flex-col gap-2 grow bg-indigo-700 text-white dark:bg-gray-700 dark:text-gray-100 p-2 py-4 text-center"
        :class="{
            'rounded lg:rounded-none lg:rounded-l-lg': halfRounded,
            'rounded': !halfRounded,
         }">
        <div class="font-bold hidden lg:block">Set Information:</div>
        <img
            v-lazy="{src: subCategory.image_path, loading: loadingGif}"
            :alt="`Image for ${subCategory.name}`"
            class="sm:rounded-lg mx-auto max-w-[128px] sm:max-w-sm lg:max-w-[128px]"
        />
        <div class="text-lg">
            {{ subCategory.game.name }}
        </div>
        <div class="text-lg">
            {{ subCategory.name }}
        </div>
        <div class="text-xs">
            <div class="flex flex-col gap-2">
                <div
                    v-for="(value, key) in subCategory.meta"
                >
                 <strong>{{ MetaFormatter.prettyPrintKey(key) }}:</strong> {{ value }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import loadingGif from "/loading.gif";
import MetaFormatter from "../../../utils/MetaFormatter";

export default defineComponent({
    name: "SubCategoryPanel",
    props:{
        subCategory: {
            type: Object,
            required: true
        },
        halfRounded:{
           type: Boolean,
           required: false,
           default: true
        }
    },
    setup(){

        return {
            MetaFormatter,
            loadingGif
        }
    }
})
</script>