<template>
    <img v-if="!isLoading" :src="iconUrl" alt="country icon" class="country-icon inline"
      :class="{
        'mt-1 mr-1': (margin),
        'w-4 h-2': (size === 'small'),
        'w-6 h-4': (size === 'medium'),
        'w-10 h-6': (size === 'large'),
        'w-24 h-16': (size === 'xlarge'),
        'rounded-full': (rounded),
      }"/>
</template>
<script lang="ts">

import {computed, defineComponent, onBeforeMount, ref, watch} from "vue";
import useMiscInformationStore from "../../../utils/useMiscInformationStore";
import {first} from "lodash";

export default defineComponent({
    name: "CountryIcon",
    props: {
        "code":{
            type: String,
            required: true
        },
        "size":{
            type: String,
            required: false,
            default: "small"
        },
        "rounded":{
            type: Boolean,
            required: false,
            default: false
        },
        "margin":{
            type: Boolean,
            required: false,
            default: true
        }
    },
    setup(props){
        const isLoading = ref(true);
        const iconUrl = ref();
        const miscInformationStore = useMiscInformationStore();

        onBeforeMount(() => {
            isLoading.value = true;
            const countries = miscInformationStore.countries;
            iconUrl.value = countries.find((c) => c.code === props.code)?.icon;
            isLoading.value = false;
        })

        watch(() => props.code, () => {
            const countries = miscInformationStore.countries;
            iconUrl.value = countries.find((c) => c.code === props.code)?.icon;
        });

        return {
            isLoading,
            iconUrl
        }
    }
})
</script>
<style>

</style>