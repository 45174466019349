<template>
  <li role="option" :id="'search-result-' + index" :aria-selected="(selected === index).toString()">
    <router-link :to="`/${result.game_slug}/${result.category_slug}/${result.slug}`" @click="selectProduct(index)" tabindex="-1" class="flex p-4 items-center" :class="{ 'bg-gray-50 dark:bg-gray-600': selected === index }" @mouseenter="selected = index" @mouseout="selected = null">

      <div class="flex flex-row gap-x-2">
          <img
              v-lazy="{ src: result.image_path, loading: loadingGif }"
              :alt="`Image for ${result.name}`"
              class="w-8 max-h-8 object-center object-cover rounded self-center"
          />
        <div class="flex flex-col gap-x-2" :class="{'text-gray-900 dark:text-white': selected !== index, 'text-teal-600 dark:text-gray-100': selected === index }">

            <span v-if="result.sub_category_series_name && result.sub_category_name" class="self-start text-xs text-gray-900 dark:text-gray-400">
          {{ result.sub_category_series_name}} > {{ result.sub_category_name }}
        </span>
            <span class="self-start text-left">{{ result.name }}</span>

        </div>
      </div>
        <div class="grow-0 hidden lg:block text-xs font-semibold py-2 px-2 uppercase rounded-full uppercase mr-0 ml-auto "
             :class="{'text-white bg-indigo-700': result.in_stock, 'text-white bg-red-700': !result.in_stock}">
            {{ result.in_stock ? "In Stock" : "Out of Stock" }}
        </div>
        <div class="grow-0 block lg:hidden text-xs font-semibold py-2 px-2 rounded-full mr-0 ml-auto "
             :class="{'text-white bg-indigo-700': result.in_stock, 'text-white bg-red-700': !result.in_stock}">
            {{ result.in_stock ? "In Stock" : "No Stock" }}
        </div>
    </router-link>
  </li>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import ProductSearchResult from "../../types/ProductSearchResult";
import loadingGif from "/loading.gif";

export default defineComponent({
  name: "SearchResult",
  props:{
    index: {
      type:Number,
      required: true
    },
    result:{
      type: Object as PropType<ProductSearchResult>,
      required: true
    }
  },
  emits:[
      'onSearchResultSelect'
  ],
  setup(props, context){

    const selected = ref();

    const selectProduct = (i: Number) => {
        context.emit('onSearchResultSelect', i);
    }

    return {
      loadingGif,
      selected,
      selectProduct
    }
  }
})
</script>