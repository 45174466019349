const metricoolTrackingEnabled = import.meta.env.VITE_ENABLE_METRICOOL === "true";
const metricoolTrackingCode = <string>import.meta.env.VITE_METRICOOL_TRACKING_CODE ?? "";

export const installMetricoolTracker = () => {
    const loadScript = (callback) => {
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://tracker.metricool.com/resources/be.js';
        script.onload = callback;
        head.appendChild(script);
    };

    if(metricoolTrackingEnabled){
        loadScript(() => {
            beTracker.t({ hash: metricoolTrackingCode });
        });
    }
};